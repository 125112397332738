import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faLocationDot  } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="./bovLogo.svg" className="App-logo" alt="logo" />
        <div className='section'>
          <p>INGA BORDSBOKNIGNAR – ENBART DROP IN.</p> 
          <p>TAKE AWAY MENY FINNS PÅ VÅR INSTAGRAM.</p>
        </div>      
        <div className='section'>
          <a href="tel: +46708259561"><FontAwesomeIcon icon={faPhone} className='icon'/></a> 
          <a href="https://www.instagram.com/bovgbg/"><FontAwesomeIcon icon={faInstagram} className='icon'/></a>
          <a href="mailto: info@bovgbg.com"><FontAwesomeIcon icon={faEnvelope} className='icon'/></a>
          <a href="https://goo.gl/maps/BKjM6umee9JqiXwh7"><FontAwesomeIcon icon={faLocationDot} className='icon'/></a>       
        </div>
      </header>
    </div>
  );
}

export default App;
